import { useCallback } from 'react';
import { transformAskOrMidPriceFn } from '../../../shared';
import { BondResponse } from '../../type';
import { makeGrossBondCalculator } from '../../utils/makeGrossBondCalculator';
import { useBondsBondTransformApi } from '../api';
import { useNumberFormatter } from '../../../../../../../Components/NumberDisplay';

const makeTransformGrossAmountFor = (nominal: number, formatNumber: (value: number, formatOptions?: Partial<Intl.NumberFormatOptions>) => string) => (bondsBond: BondResponse | undefined) => {
  if (bondsBond == null) {
    return undefined;
  }
  const price = transformAskOrMidPriceFn(bondsBond);
  if (price == null) {
    return undefined;
  }
  const calculator = makeGrossBondCalculator(bondsBond);
  const {
    accrued,
  } = calculator;
  const value = nominal * ((price / 100) + accrued);
  const formattedValue = formatNumber(value, {
    currency: bondsBond['C_CURRENCY.CODE'],
  });
  return formattedValue;
};

const format: Parameters<typeof useNumberFormatter>[0] = {
  format: {
    style: 'currency',
  },
};

export const useGrossAmountFor = (nominal: number) => {
  const formatNumber = useNumberFormatter(format);
  const transformGrossAmountFor10K = useCallback(makeTransformGrossAmountFor(nominal, formatNumber), [nominal]);
  const response = useBondsBondTransformApi(transformGrossAmountFor10K);
  return response;
};
