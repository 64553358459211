import * as React from 'react';
import { useBondsBondIsSomeRedacted, useGrossAmountFor } from '../../hooks';
import { ActorApiStringDisplay } from '../../../../../../components/display/string';

export const GrossAmountFor = (props: {
  nominal: number,
  withRedactedIcon?: boolean,
}) => {
  const {
    nominal,
    withRedactedIcon,
  } = props;
  const {
    data,
    isLoading,
    error,
  } = useGrossAmountFor(nominal);
  const {
    data: redacted,
    isLoading: redactedIsLoading,
  } = useBondsBondIsSomeRedacted([
    'S_QUOTE.PRICE_CLOSE',
    'S_QUOTE.PRICE_MAX',
    'S_ACC.INT_RATE',
    'S_ACC.OBL_END_DATE',
    'S_ACC.OBL_END_PRICE',
    'S_ACC_JRS.FREQ',
    'S_ACC.ISSUE_DATE',
    'S_ACC.OBL_FIRST_CPS',
  ]);
  return (
    <ActorApiStringDisplay
      data={data}
      isLoading={isLoading || redactedIsLoading}
      error={error}
      isBlocked={redacted}
      withRedactedIcon={withRedactedIcon}
      loadingValue="-- ---.-- ---"
    />
  );
};
