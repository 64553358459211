/* eslint-disable no-underscore-dangle */
import { sanityClient } from '../../../client';
import { slugify } from '../../../utils';
import { query } from './query';
import {
  QueryArguments, SwrArguments, QueryResponse, BaseQueryResponse,
} from './types';

const convertSwrArguments = (args: SwrArguments): QueryArguments => {
  const [, locale, bondId] = args;
  return {
    locale,
    bondId,
  };
};

export const convertQueryArguments = (args: QueryArguments): SwrArguments => {
  const {
    locale,
    bondId,
  } = args;
  return [query, locale, bondId];
};

export const fetcher = async (args: SwrArguments): Promise<QueryResponse | null> => {
  const [queryString] = args;
  const sanityArguments = convertSwrArguments(args);
  const {
    locale,
  } = sanityArguments;
  const response = await sanityClient.fetch<BaseQueryResponse | null, QueryArguments>(queryString, sanityArguments);
  return response != null
    ? {
      ...response,
      slug: slugify({
        id: response._id,
        title: response.title,
        locale,
        date: response.publishDate,
      }),
    }
    : null;
};
