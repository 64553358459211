import * as React from 'react';
import {
  BondsBondSMarketYield,
  BondsBondSQuoteArchiveDate,
  BondsBondSQuotePrice,
  GrossAmountFor,
} from '../../../../actor/api/Services/bonds';
import { SanityBondFieldContainer } from './components';

export const SanityBondField = (props) => {
  const {
    value,
  } = props;
  const {
    id,
    field,
    bold,
    italic,
    underline,
    strike,
  } = value;
  switch (field) {
    case 'yield':
      return (
        <SanityBondFieldContainer
          id={id}
          bold={bold}
          italic={italic}
          underline={underline}
          strike={strike}
        >
          <BondsBondSMarketYield />
        </SanityBondFieldContainer>
      );
    case 'price':
      return (
        <SanityBondFieldContainer
          id={id}
          bold={bold}
          italic={italic}
          underline={underline}
          strike={strike}
        >
          <BondsBondSQuotePrice />
        </SanityBondFieldContainer>
      );
    case 'price_date':
      return (
        <SanityBondFieldContainer
          id={id}
          bold={bold}
          italic={italic}
          underline={underline}
          strike={strike}
        >
          <BondsBondSQuoteArchiveDate />
        </SanityBondFieldContainer>
      );
    case 'gross_amount_for_10k':
      return (
        <SanityBondFieldContainer
          id={id}
          bold={bold}
          italic={italic}
          underline={underline}
          strike={strike}
        >
          <GrossAmountFor nominal={10_000} />
        </SanityBondFieldContainer>
      );
    default:
      return null;
  }
};
