export const query = /* groq */`
*[
  _type == "news"
  && language == $locale
  && dateTime(publishDate.utc) > dateTime(now()) - 60 * 60 * 24 * 31 * 3
  && publishDate.utc <= now()
  && (
    $bondId in bondIds
    || content[].markDefs[_type == "link"].href match "*/bonds/details/" + string($bondId)
    || $bondId in content[].markDefs[_type == "bondLink"].securityId
    || $bondId in content[_type == "securityPriceGraph"].securityId
  )
]
| order(publishDate.utc desc)
{
  _id,
  language,
  title,
  "publishDate": publishDate.utc,
}[0]
`;
