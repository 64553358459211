import useSWR, { SWRResponse } from 'swr';
import { useAtomValue } from 'jotai';
import { QueryArguments, QueryResponse, SwrArguments } from '../types';
import { convertQueryArguments, fetcher } from '../utils';
import { useBondSAccId } from '../../../../../actor/api/Services/bonds';
import { pathnameUrlLanguageAtom } from '../../../../../history';

export const useSanityNewsByBondId = (props: QueryArguments): SWRResponse<QueryResponse | null, any, any> => {
  const swrArguments: SwrArguments = convertQueryArguments(props);
  const response = useSWR(swrArguments, fetcher);
  return response;
};

export const useSanityNewsByBondIdWithContext = () => {
  const bondId = useBondSAccId();
  const locale = useAtomValue(pathnameUrlLanguageAtom);
  return useSanityNewsByBondId({
    bondId,
    locale,
  });
};
